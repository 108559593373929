import readXlsxFile from 'read-excel-file';
import writeXlsxFile from 'write-excel-file';
import React, {useState} from 'react';
import {useEffect} from 'react';
import {useContext} from 'react';
import {FileContext} from '../contexts/Contexts';
import {Button} from '@mui/material';
import {FileDownload} from '@mui/icons-material';
import {ThemeProvider, createTheme} from '@mui/material/styles';

const theme = createTheme({
  palette:{
    primary: {
      main:'#1e90ff',
    },
    secondary:{
      main:'#ff0000',
    }, 
  },
});
function TableComponent() {
  //const [table, setTable] = useState(null);
  const {rFile, setRFile, table, setTable, title, setTitle, slots_cors, setSlots_cors, corsindex, setCorsindex, corstds, setCorstds, stdsindex, setStdsindex, uid, setUid} = useContext(FileContext);
  useEffect(() => {
    //setTable(document.getElementById('excel-table'));
    document.getElementById('main-div').appendChild(table);
  },[]);
  useEffect(() => {
    if(!table.hasChildNodes()){
      fileChangeHandler();
    }
  },[rFile]);
  const input = document.getElementById('file-input');
  //const table = document.getElementById('excel-table');
  /**
   * @param numOfSteps: Total number steps to get color, means total colors
   * @param step: The step number, means the order of the color
   */
  function rainbow(numOfSteps, step) {
      // This function generates vibrant, "evenly spaced" colours (i.e. no clustering). This is ideal for creating easily distinguishable vibrant markers in Google Maps and other apps.
      // Adam Cole, 2011-Sept-14
      // HSV to RBG adapted from: http://mjijackson.com/2008/02/rgb-to-hsl-and-rgb-to-hsv-color-model-conversion-algorithms-in-javascript
      var r, g, b;
      var h = step / numOfSteps;
      var i = ~~(h * 6);
      var f = h * 6 - i;
      var q = 1 - f;
      switch(i % 6){
          case 0: r = 1; g = f; b = 0; break;
          case 1: r = q; g = 1; b = 0; break;
          case 2: r = 0; g = 1; b = f; break;
          case 3: r = 0; g = q; b = 1; break;
          case 4: r = f; g = 0; b = 1; break;
          case 5: r = 1; g = 0; b = q; break;
      }
      var c = "#" + ("00" + (~ ~(r * 255)).toString(16)).slice(-2) + ("00" + (~ ~(g * 255)).toString(16)).slice(-2) + ("00" + (~ ~(b * 255)).toString(16)).slice(-2);
      return (c);
  }
  
  const intersection = (a, b) => {
    if(b.size < a.size){
      var tmp = a;
      a = b;
      b = tmp;
    }
    var res = new Set();
    a.forEach((e) => {
      if(b.has(e)){
        res.add(e);
      }
    })
    return res;
  }

  const union = (org, targ) => {
    targ.forEach((e) => {
      org.add(e);
    })
  }

  async function xlsxFileSaveHandler(){
    console.log("saving xlsx file");
    console.log(slots_cors);
    
    const m = slots_cors.length;
    const n = slots_cors[0].length;
    //saving the 1st sheet which is raw data
    const data0 = [];
    const columns0 = [];
    const headrow0 = [];
    columns0.push({width:12});
    headrow0.push({});
    for(let j = 1; j < corsindex.size+1; j++){
      columns0.push({width:2*corsindex.get(j).length});
      headrow0.push({type:String, value:corsindex.get(j), wrap:true, fontSize:10, fontWeight:"bold", height:40, align:"center", alignVertical:"center", backgroundColor:"#FF0000"});
    }
    data0.push(headrow0);
    console.log("checking in progress");
    console.log(stdsindex);
    console.log(corsindex);
    console.log(corstds);
    for(let i = 1; i < stdsindex.size+1; i++){
      let row = [];
      row.push({type:String, value:stdsindex.get(i), wrap:true, fontSize:10, fontWeight:"bold", height:40, align:"center", alignVertical:"center", backgroundColor:"#00FF00"});
      for(let j = 1; j < corsindex.size+1; j++){
        console.log("j",j);
        if(corstds.get(corsindex.get(j)).has(stdsindex.get(i))){
          row.push({type:Number, value:1, wrap:true, fontSize:10, fontWeight:"bold", height:40, align:"center", alignVertical:"center", backgroundColor:"#FF0000"});
        }
        else{
          row.push({});
        }
      }
      data0.push(row);
    }
    console.log("check log");
    console.log(corsindex);
    //saving the 2nd sheet which is a table
    const data = [];
    const columns = [];
    const headrow = [];
    headrow.push({type:String, value: title, span:n+1, fontSize:20, fontWeight:"bold", height:40, align:"center", alignVertical:"center", backgroundColor:"#FF0000"})
    for(let j = 1; j < n+1; j++){
      headrow.push(null);
    }
    data.push(headrow);
    const daysrow = [];
    daysrow.push(null);
    for(let j = 1; j < n+1; j++){
      daysrow.push({wrap:true, value:"DAY".concat(j.toString()), fontSize:10, fontWeight:"bold"});
    }
    data.push(daysrow);
    columns.push({width:20})
    for(let j = 1; j < n+1; j++){
      columns.push({width:0})
    }
    //data[0] is already headrow
    //data[1] is a row for days
    for(let i = 0; i < m; i++){
      data.push([])
      data[data.length-1].push({wrap:true, value:(Math.floor(i/2)+1).toString(), fontSize:10, fontWeight:"bold"})
      for(let j = 1; j < n+1; j++){
          columns[j].width = Math.max(columns[j].width, Math.max(...Array.from(slots_cors[i][j-1]).map(e => e.length)));
          data[data.length-1].push({wrap:true, fontSize:10, fontWeight:"bold", type:String, value: Array.from(slots_cors[i][j-1]).join("\n")});
      }
    }
    console.log("printing uid");
    console.log(uid);
    //uploading current table file to S3 bucket
    await writeXlsxFile([data0, data], {columns:[columns0,columns], sheets:['stdsdata','timetable']})
      .then(resultFile => {
        console.log("result file check");
        console.log(resultFile);
        const formData = new FormData();
        formData.append(
          "file",
          resultFile,
          'output2025.xlsx'
        );
        const requestOptions = {
          method:'POST',
          body: formData
        };
        fetch(`https://tyrano.daalgo.org/file/upload_result_file/${uid}`, requestOptions)
        //fetch(`http://127.0.0.1:8000/file/upload_result_file/${uid}`, requestOptions)
        .then(data => {
          console.log("check message");
          console.log(data);
        });
        console.log("download result xlsx file after saving to db")
        console.log("check result file before");
        console.log(resultFile)
        var a = document.createElement('a');
        var url = window.URL.createObjectURL(resultFile);
        a.href = url;
        a.download = "output2025.xlsx";
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        a.parentNode.removeChild(a);
        console.log("check resultFile after");
        console.log(resultFile);
      });
  }//end of xlsxFileSaveHandler
  
  console.log("EPL check");
  //var title = "";
  const slots = [];
  //const slots_cors = [];
  //const corstds =  new Map();
  //const corsindex = new Map();
  //const stdsindex = new Map();
  const cortoslot = new Map();
  const cortimes = new Map();
  const comps = new Map();
  const corcolors = new Map();
  const stack = [];///////
  var coloridx = 0;
  const TCOLOR = 100;//the total number of colors
  const delta = 29;//relatively prime to 100, total # of colors
  async function fileChangeHandler(){
    console.log("inside handler");
    console.log("table", table);
    //const file = e.target.files[0]
    //reading the first sheet
    await readXlsxFile(rFile, {sheet:1}).then((rows) =>{
      rows.forEach((row,i) => {
        row.forEach((cell,j) => {
          if(i>0 || j>0){
            if(i===0){
              var cor = cell;
              corstds.set(cor,new Set());
              corsindex.set(j, cor);
            }
            else if(j===0){
              var std = cell;
              stdsindex.set(i, std);
            }
            else if(cell == 1){
              corstds.get(corsindex.get(j)).add(stdsindex.get(i));
            }
          }
        })
      });
    });
    //reading the second sheet
    await readXlsxFile(rFile, {sheet:2}).then((rows) =>{
      rows.forEach((row,i) => {
        let tr = document.createElement('tr');
        if(i>=2){
          slots.push([]);
          slots_cors.push([]);
        }
        row.forEach((cell,j) => {
          const td = document.createElement('td');
          td.style.borderColor = "#000000";
          td.style.borderWidth = "2px";
          const tdid = i.toString().concat("|",j.toString());
          if(i>=2 && j >=1){
            td.id = tdid;
            slots[slots.length-1].push(td);
            slots_cors[slots_cors.length-1].push(new Set());
            td.draggable = false;
            td.addEventListener('dragover',(ev) => {
              ev.preventDefault();
              console.log("ondragover");
            }, false);
            td.addEventListener('drop',(ev) =>{
              ev.preventDefault();
              while(stack.length > 0){////
                let e = stack.pop();////
                e.style.borderColor = "#000000";////
                e.style.borderWidth = "2px";////
              }////
              var pcor = ev.dataTransfer.getData("prevcor");
              var hours = cortimes.get(pcor);
              var pslotidxes = cortoslot.get(pcor).split("|").map((e) => (parseInt(e)));
              pslotidxes = [pslotidxes[0]-2, pslotidxes[1]-1]
              var tslotidxes = ev.currentTarget.id.split("|").map((e) => (parseInt(e)));
              tslotidxes = [tslotidxes[0]-2,tslotidxes[1]-1]
              if(tslotidxes[0]+hours <= slots.length){
                var pcomps = comps.get(pcor);
                var q = pslotidxes[1];
                var common = new Set();
                for(let p = pslotidxes[0]; p < pslotidxes[0] + hours; p++){
                  slots_cors[p][q].delete(pcor);
                }
                q = tslotidxes[1];
                for(let p = tslotidxes[0]; p < tslotidxes[0] + hours; p++){
                  console.log(slots_cors[p][q]);
                  slots_cors[p][q].forEach((cor) => {
                    var pidx = pcor.lastIndexOf('(');
                    var cidx = cor.lastIndexOf('(');
                    union(common, intersection(corstds.get(pcor.substring(0,pidx)), corstds.get(cor.substring(0,cidx))));
                  })
                }
                console.log(common);
                if(common.size > 0){//backtrack
                  console.log("intersection",common);
                  q = pslotidxes[1];
                  for(let p = pslotidxes[0]; p < pslotidxes[0] + hours; p++){
                    slots_cors[p][q].add(pcor);
                  }
                }
                else{
                  q = pslotidxes[1];
                  for(let p = pslotidxes[0]; p < pslotidxes[0] + hours; p++){
                    slots[p][q].removeChild(pcomps[p-pslotidxes[0]]);
                  }
                  cortoslot.set(pcor, ev.currentTarget.id);
                  q = tslotidxes[1];
                  for(let p = tslotidxes[0]; p < tslotidxes[0] + hours; p++){
                    slots[p][q].appendChild(pcomps[p-tslotidxes[0]]);
                    slots_cors[p][q].add(pcor);
                  }  
                }//end of else
              }//end of if
            }, true);
          }//end of if(i>=2 && j >=1)
          
          //insert current cors to td
          if(i>=2 && j>=1){
            const curcors = cell?.split('\n')
            curcors?.forEach((cor, k)=>{
              slots_cors[i-2][j-1].add(cor);
              const tdiv = document.createElement('div');
              if(corcolors.has(cor)){
                tdiv.style.backgroundColor = corcolors.get(cor);
              }
              else{
                corcolors.set(cor, rainbow(TCOLOR, coloridx));
                coloridx = (coloridx + delta)%TCOLOR;
                tdiv.style.backgroundColor = corcolors.get(cor);
              }
              tdiv.textContent = cor;
              if(!comps.has(cor)){
                comps.set(cor,[]);
              }
              comps.get(cor).push(tdiv);
              if(!cortoslot.has(cor)){
                cortoslot.set(cor, tdid);
                cortimes.set(cor, 1);
              }
              else{
                cortimes.set(cor, cortimes.get(cor) + 1);  
              }
              tdiv.draggable = true;
              tdiv.style.border = "thin solid #FFFFFF";  
              tdiv.id = i.toString().concat("|",j.toString(),"|",k.toString());
              tdiv.addEventListener('dragstart', (ev) =>{
                ev.dataTransfer.setData("prevcellid", ev.target.id);
                ev.dataTransfer.setData("prevcor", ev.target.textContent);
                
                let curcor = ev.target.textContent;
                let h = cortimes.get(curcor);
                let slotpos = cortoslot.get(curcor).split("|").map((e) => (parseInt(e)));
                slotpos = [slotpos[0]-2, slotpos[1]-1];
                for(let i = slotpos[0]; i < slotpos[0]+h; i++){
                  slots_cors[i][slotpos[1]].delete(curcor);
                }
                let curidx = curcor.lastIndexOf('(');

                for(let i = 0; i <= slots.length - h; i++){
                  for(let j = 0; j < slots[0].length; j++){
                    let common = new Set();
                    for(let k = i; k < i+h; k++){
                      slots_cors[k][j].forEach((cor) => {
                        let cidx = cor.lastIndexOf('(');
                        union(common, intersection(corstds.get(curcor.substring(0,curidx)), corstds.get(cor.substring(0,cidx))));
                        if(common.size > 0){
                          return false;
                        }
                      })
                      if(common.size>0){
                        break;
                      }
                    }
                    if(common.size == 0){
                      stack.push(slots[i][j]);
                    }
                  }
                }
                stack.forEach((td) => {
                  td.style.borderColor = "#FF7878";
                  td.style.borderWidth = "5px";
                });
                console.log("stack", stack);
                for(let i = slotpos[0]; i < slotpos[0] + h; i++){
                  slots_cors[i][slotpos[1]].add(curcor);
                }
              }, false);
              td.appendChild(tdiv);   
            });
          }
          else{
            const tdiv = document.createElement('div');
            if(i == 0 && j == 0){
              setTitle(cell);
              //title = cell;
            }
            tdiv.textContent = cell;
            td.appendChild(tdiv);
          }
          if(i>0){
            tr.appendChild(td);  
          }
        })
        table.appendChild(tr);
      })
    })//end of readxlsxfile of the second sheet
  }
  return (
    <div className="App-column" id = "main-div">
      <ThemeProvider theme = {theme}>
      <Button onClick = {xlsxFileSaveHandler} variant = "contained" style = {{marginTop:10, marginBottom:10}} color = "primary" startIcon={<FileDownload />}>
      Save
      </Button>
      </ThemeProvider>
      { 
        table != null &&
        <div className = "App-table-head1" style ={{marginTop:10, marginBottom:10}}>{title}</div>
      }
    </div>
  );
}

export default TableComponent;
